$breakpoint-tablet: 1100px;
@media (min-width: $breakpoint-tablet) {
  .nav_link_text {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
  }
}

@media (max-width: $breakpoint-tablet) {
  .nav_link_text {
    display: none;
  }
}

.name {
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  margin-block-start: 0;
  margin-block-end: 0;
  text-decoration: none;
  color: white;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em 0 2em;
  margin-bottom: 2em;
}

.nav_ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
}

.nav_item {
  margin: 0em 0.5em 0 0.5em;
}

.nav_link_text:hover {
  font-weight: bolder;
}
