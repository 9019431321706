$breakpoint-tablet: 1100px;
@media (max-width: $breakpoint-tablet) {
  .wb-left {
    flex-direction: column;
  }

  .wb-right {
    flex-direction: column;
  }

  .work-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8em 10em;
    margin-bottom: 50%;
  }
  .story-text {
    background: rgb(20, 20, 20);
    min-width: 18em;
    max-width: 25em;
    font-size: 1.1em;
    text-align: justify;
    border-radius: 0.5em;
    padding: 1em;
  }
  .professional-image {
    width: 18em;
  }
}

@media (min-width: $breakpoint-tablet) {
  .animated-container {
    display: flex;
    justify-content: center;
  }

  .wb-left {
    flex-direction: row;
  }

  .wb-right {
    flex-direction: row-reverse;
  }

  .work-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 8em 10em;
    width: 100%;
    max-width: 150em;
  }

  .story-text {
    background: rgb(36, 33, 33);
    min-width: 19em;
    max-width: 30em;
    font-size: 1.5em;
    padding: 1em;
    border-radius: 0.5em;
    border-style: solid;
    border-width: 0.1em;
    border-color: rgb(134, 171, 252);
  }
  .professional-image {
    width: 30em;
  }
}

@media (min-width: 1200px) {
  .story-text {
    font-size: 1.8em;
  }
}

.side-image {
  width: 15em;
}

.professional-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  margin: 1em 3em;
}

.professional-image img {
  display: block;
  width: 100%;
  height: 100%;
}

.resume {
  width: 44em;
}

.resume-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.resume-link {
  display: flex;
  justify-content: flex-end;
  width: 42em;
  a {
    color: white;
    text-decoration: none !important;
    border: 0.1em solid white;
    padding: 0.3em;
  }
}

.email-link {
  display: inline-block;
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

.professional-text {
  p {
    font-size: 1em;
    text-align: center;
    width: 15em;
    margin: 0.8em 0;
  }
  width: 100%;
  margin: 0 1em;
  display: flex;
  justify-content: center;
}

.link-icon {
  cursor: pointer;
  padding: 0.25em;
}

.vert-image-stack {
  display: flex;
  flex-direction: column;
}
