$breakpoint-tablet: 1100px;
@media (max-width: $breakpoint-tablet) {
  .story-group {
    display: flex;
    flex-direction: column;
  }
  .story-group:last-child {
    margin-bottom: 5em;
  }

  .two-stack {
    display: flex;
    flex-direction: column;
  }
  .two-stack img {
    width: 70vw;
    margin-bottom: 1em;
  }
  .main-story-image {
    display: flex;
    flex-direction: column;
    width: 70vw;
    position: relative;
    margin-bottom: 1em;
  }

  .story-tags {
    display: none;
  }

  .overlay-text {
    display: none;
  }
}

@media (min-width: $breakpoint-tablet) {

  .story-group {
    display: flex;
    flex-direction: row;
    margin: 6em;
    background: rgb(20, 20, 20);
    padding: 2em;
    padding-bottom: 3em;
    border-radius: 0.5em;
  }
  .two-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 32.2em;
  }
  .two-stack img {
    width: 18.5em;
    height: 15em;
  }
  .main-story-image {
    display: flex;
    flex-direction: column;
    width: 40em;
    position: relative;
    height: 32em;
    margin: 0 2em;
  }

  .main-story-image img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .main-story-image:hover .overlay {
    opacity: 0.9;
    transition: 0.5s;
  }

  .overlay {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: #27221f;
    border-radius: 0.5em;
  }

  .overlay-text {
    padding: 2em;
    font-size: 1.5em;
    text-align: justify;
  }
}

.two-stack img {
  border-radius: 0.5em;
}

.main-story-image img {
  border-radius: 0.5em;
  aspect-ratio: 5/4;
}

.tigers {
  width: 20em;
}

.tiger-left {
  margin-top: 5em;
  margin-left: 2em;
}

.tiger-right {
  margin-top: 2em;
  margin-right: 5em;
}

.switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gallery-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.story-group:first-child {
  margin-top: 2em;
}
