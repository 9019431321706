html {
  background-color: black;
  color: white;
}

$breakpoint-tablet: 1100px;
$breakpoint-phone: 700px;

.profile-box {
  background: #27221fb7;
  border-radius: 0.3em;
  border-style: solid; 
  border-width: 0.1em;
  border-color: royalblue;
  padding: 2em;
  h1 {
    margin: 0;
  }
  p {
    text-align: left;
  }
}

.nav-link-button {
  display: inline-block;
  border: 0.05em solid white;
  color: white;
  cursor: pointer;
  text-decoration: none;
  padding: 0.3em;
  text-transform: uppercase;
  margin: 0.5em;
}

.nav-link-button:hover {
  box-shadow: 0 0 0 0.08em white;
  transition-duration: 0.5s;
}

@media screen and (min-width: $breakpoint-tablet) {
  .nav-link-button {
    font-size: 1.5em;
  }
  .nav-link-button:hover {
    transition-duration: 0.5s;
  }
  
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 2em;
    margin-right: 2em;
  }
  .content:last-child {
    margin-bottom: 3em;
  }
  .content-box {
    display: flex;
    flex-direction: row;
    margin: 2em;
    align-items: center;
  }
  .quote {
    display: flex;
    align-items: center;
    font-size: 8vh;
    font-weight: 300;
    width: 9em;
    font-family: "adam.cg_proregular";
  }
  .xueImage {
    height: 40em;
    width: 16.5em;
  }
  .profile-box {
    width: 50em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    h1 {
      font-size: 5em;
      font-weight: 100;
    }
    p {
      font-size: 1.5em;
      width: 17em;
      margin: 0.8em 0;
    }
    .professional-box {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      width: 19em;
      padding-left: 2.5em;
    }
  }
}

@media screen and (min-width: $breakpoint-phone) and (max-width: $breakpoint-tablet) {
  .profile-box {
    h1 {
      font-size: 3em;
      font-weight: 200;
    }
    p {
      font-size: 1.6em;
      max-width: 17.5em;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 1em;
    width: 100%;
  }
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .quote {
    display: none;
  }
  .xueImage {
    height: 85vh;
    object-fit: contain;
  }
}

.xueImageContainer {
  display: flex;
  align-items: center;
}

.professional-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.media-icon {
  margin: 0.5em;
}

@media screen and (max-width: $breakpoint-phone) {
  .profile-box {
    max-width: 25em;
    background: none;
    border: none;
    h1 {
      font-size:calc(30px + 1.5vw);
      font-weight: 100;
    }
    p {
      font-size:calc(16px + 1.5vw);
      max-width: 15em;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 1em;
    width: 100%;
  }
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .quote {
    display: none;
  }
  .xueImage {
    height: 40em;
    width: 16.5em;
  }
}

.xueImageContainer {
  display: flex;
  align-items: center;
}

.professional-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.media-icon {
  margin: 0.5em;
}
