$breakpoint-tablet: 1100px;
$breakpoint-phone: 700px;

@media screen and (max-width: $breakpoint-tablet) {
  .story-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 1em;
    margin-top: 0.5em;
    margin-bottom: 5em;
  }
  
  .main-text {
    font-size: 2em;
  }

  .parallax-image {
    display: none;
  }
}
@media screen and (min-width: $breakpoint-tablet) {
  .main-text {
    font-size: 4em;
  }
  .story-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1em;
    margin-top: 0.5em;
    min-width: 18em;
    justify-content: center;
  }
}

@media screen and (max-width: $breakpoint-phone) {
  html, body {
    overflow-x: hidden;
  }
  body {
    position: relative
  }
}

// base background for all pages
body {
  background-color: #27221fb7;
}

.background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.content {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 100vh;
}

.button {
  margin: 1em;
  background: none;
  border: none;
  color: white;
}

.button:hover {
  color: #27221f;
  cursor: pointer;
  transition: 0.3s;
}

.buttonOn {
  text-decoration: underline;
}

.fade-in {
  animation: fadeIn ease 2s;
}

.main-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  width: 9em;
  padding: 0 1em;
  font-family: "adam.cg_proregular";
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.story-tags p {
  border: 1px solid white;
  width: auto;
  padding: 0.05em 0.5em;
  margin: 0.2em;
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
}
